<template>
  <Breadcrumb />
  <div class="d-flex justify-content-end">
    <router-link
      :to="{
        name: 'LocalizationLanguageNew',
        params: {},
      }"
      class="btn btn-success btn-action-new-page"
    >
      <i class="bi bi-plus"></i>
      {{
        $t(
          "LocalizationLanguages.NewButton",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}
    </router-link>
  </div>
  <Grid :settings="gridSettings" :columns="gridColumns" />
</template>
<script>
export default {
  name: "LocalizationLanguage",
  components: {},
  data() {
    return {
      gridSettings: {
        action: "LocalizationLanguage",
        requestUrl: String.format("/Brs-LocalizationLanguageList"),
        routeRequiredParameters: [
          {
            key: "localizationLanguageId",
            value: "publicId",
            isRouteParameter: false,
          },
        ],
        //isBrsRequest: true, //!! axios instance changed
        isGatewayRequest: true,
        allowSearchPanel: true,
        allowExcelExport: false,
        allowDragAndDrop: false,
        allowPaging: true,
        allowDeleting: true,
        deleteActionUrl: "/Brs-LocalizationLanguageDelete",
        autoOrderProcess: true,
        buttons: [
          {
            name: "edit",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: true,
            routeObj: {
              name: "LocalizationLanguageEdit",
              params: {
                localizationLanguageId: "",
              },
            },
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "CustomButtons.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
      ],
    };
  },
  methods: {},
  mounted() {},
};
</script>
